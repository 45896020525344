import Datasource from './datasource';

export default class Datasources {
  sources = {};
  models = {};

  update(sources = [], models) {
    for (let model of sources) {
      const datasource = this.sources[model.key];
      if (datasource) {
        datasource.update(model);
      } else {
        this.sources[model.key] = new Datasource(model);
      }
    }
    this.models = models || {};
  }

  get(datasourceKey, path) {
    if (!this.sources[datasourceKey]) return Promise.reject();
    return this.sources[datasourceKey].get(path);
  }

  getOpts(sourceKey, modelKey) {
    var res = {};
    const promises = [];

    const model = this.models[modelKey];
    if (!model) return Promise.reject('Model does not exist: ' + modelKey);

    for (var key in model) {
      promises.push(this.get(sourceKey, model[key]).then(function(key, value) {
        res[key] = value;
      }.bind(this, key)));
    }

    return Promise.all(promises).then(() => res);
  }
}
