import riot from 'riot';

export default class PageTransition {

  disabled = false;

  constructor(timeline) {
    this.timeline = timeline;
    riot.observable(this);

    for (var attr of ['progress', 'totalTime', 'play', 'pause', 'reverse', 'isActive']) {
      this[attr] = this.timeline[attr].bind(this.timeline);
    }
  }

  isDisabled() {
    return this.disabled || this.timeline.isActive();
  }

  destroy() {
    this.disabled = true;
    delete this.timeline;
    this.trigger('destroyed');
    this.off('*');
  }
}
