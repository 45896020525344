export function onLiveEvent(tagname: string, eventName: string, callback: (e: MouseEvent) => void) {
  document.body.addEventListener(eventName, (e: MouseEvent) => {
    const target = findParent(tagname.toLowerCase(), e.target || e.srcElement);
    if (target) {
      callback(e, target);
    }
  });
}

export function offLiveEvent(eventname, callback) {
  document.body.removeEventListener(eventname, callback);
}

export function findParent(tagname: string, el: HTMLElement, levels = 10) {
  while (el && levels--) {
    if ((el.nodeName || el.tagName).toLowerCase() === tagname) {
      return el;
    }
    el = el.parentNode;
  }
  return null;
}
