import { getGsapEasing } from './easing';
import TweenLite from 'TweenLite';
import TimelineLite from 'TimelineLite';

const DEFAULT_TIME = 1;

export default class TimelineManager {
  scale: Object = {};
  replace: { [string]: string } = {};
  format: { [string]: (value: any) => any } = {};
  timeline: TimelineLite;

  constructor(onStart, onComplete, onReverseComplete) {
    this.onStart = onStart;
    this.onComplete = onComplete;
    this.timeline = new TimelineLite({
      paused: true,
      onStart: onStart,
      onComplete: onComplete,
      onReverseComplete: onReverseComplete,
      autoRemoveChildren: false
    });

    for (var attr of ['progress', 'totalTime', 'play', 'pause', 'reverse', 'isActive', 'timeScale']) {
      this[attr] = this.timeline[attr].bind(this.timeline);
    }
  }

  addReplace(attr: string, replaceAttr: string) {
    this.replace[attr] = replaceAttr;
  }

  addFormat(attr: string, formatFunc: (value: any) => any) {
    this.format[attr] = formatFunc;
  }

  setScaling(scale: Object) {
    this.scale = scale;
  }

  clear(triggerComplete: boolean) {
    const tl = this.timeline;
    if (triggerComplete && tl.progress() < 1) {
      this.onComplete();
    }
    tl.kill();
    tl.clear();
  }

  createTween(target, absDuration, attr, fromValue, toValue, easing, startOffset, endOffset) {
    startOffset *= absDuration;
    endOffset *= absDuration;
    if (this.format[attr]) {
      fromValue = this.format[attr](fromValue);
      toValue = this.format[attr](toValue);
    }
    if (this.replace[attr]) attr = this.replace[attr];
    return TweenLite.fromTo(
      target,
      endOffset - startOffset,
      {
        [attr]: fromValue
      },
      {
        [attr]: toValue,
        ease: getGsapEasing(easing)
      }
    ).delay(startOffset);
  }

  set(target, style) {
    TweenLite.set(target, style);
  }

  clearStyle(...targets: HTMLElement) {
    for (var target of targets) {
      TweenLite.set(target, { clearProps: 'all' });
    }
  }

  buildTweenToValues(target, tweens, toValues) {
    if (!tweens || !target) return;
    if (!(tweens instanceof Array)) tweens = [tweens];

    const { timeline, scale } = this;
    // Cloning to values to not screw them up when writing to the object
    toValues = Object.assign({}, toValues);
    // Calculate the total time for all the tweens, because we need to build the tween backwards becuase the end values is known
    var currentTime = tweens.reduce((value, tween) => value + (tween.time || 1), 0);

    var i = tweens.length;
    // Loop over the tweens backwards where the first tweens is set to the to values
    while (i--) {
      var tween = tweens[i];
      const time = tween.time || DEFAULT_TIME;
      currentTime -= time;
      for (var attr in tween) {
        if (attr === 'time') continue;

        let [value, easing, startOffset, endOffset] = tween[attr];
        if (scale[attr]) value *= scale[attr];

        timeline.add(
          this.createTween(
            target,
            time,
            attr,
            value,
            toValues[attr],
            easing,
            startOffset,
            endOffset
          ),
          currentTime
        );
        toValues[attr] = value;
      }
    }
  }

  buildTweenFromValues(target, tweens, fromValues) {
    if (!tweens || !target) return;
    if (!(tweens instanceof Array)) tweens = [tweens];

    const { timeline, scale } = this;
    fromValues = Object.assign({}, fromValues);
    var currentTime = 0;
    for (let tween of tweens) {
      const time = tween.time || DEFAULT_TIME;
      for (let attr in tween) {
        if (attr === 'time') continue;

        let [value, easing, startOffset, endOffset] = tween[attr];
        if (scale[attr]) value *= scale[attr];

        timeline.add(
          this.createTween(
            target,
            time,
            attr,
            fromValues[attr],
            value,
            easing,
            startOffset,
            endOffset
          ),
          currentTime
        );
        fromValues[attr] = value;
      }

      currentTime += time;
    }
  }
}
