import riot from 'riot';
import TimelineManager from '../animation/timeline-manager';
import Viewer from '../tags/viewer';

const RESIZABLE_IMAGE = /googleusercontent.com\//;
const IMG_SIZES = [500, 800, 1080, 1600, 2000, 2600];
const VIEWER_EVENTS = {
  viewerScroll: 'scroll',
  viewerWheel: 'wheel',
  viewport: 'viewport',
  devicemode: 'devicemode',
  page: 'page',
  preloadPage: 'preloadPage',
  page: 'page',
  state: 'state'
};

const widgetMixin = {
  init: function() {
    this.viewer = this.parent.viewer;
    this.mapContent(this.opts.content);
    this.on('update', () => this.mapContent(this.opts.content));
    this.host = this.root;
    let stateCb = state => {
      if (state.keys.indexOf(this.parent.key) !== -1) {
        this.trigger('state', state);
      } else if (state.prevKeys.indexOf(this.parent.key) !== -1) {
        this.trigger('state', { deselect: true });
      }
    };

    this.viewer.on('state', stateCb);
    this.__listeners = [['state', stateCb]];

    this.on('unmount', () => {
      for (var listener of this.__listeners) {
        this.viewer.off(listener[0], listener[1]);
      }
    });
  },

  mapContent(content) {
    for (let key in content) {
      this.opts[key] = content[key];
    }
  },

  timeline(onStart, onComplete) {
    return new TimelineManager(onStart, onComplete);
  },

  isDev() {
    return this.viewer._dev;
  },

  getImgUrl(url, size) {
    if (size && RESIZABLE_IMAGE.test(url)) {
      if (typeof size === 'string') {
        size = this.viewer.getViewport()[2] * (parseFloat(size) || 0) / 100;
      }
      var i = IMG_SIZES.length;
      var closest = IMG_SIZES[i - 1];
      while (i--) {
        var imgSize = IMG_SIZES[i];
        if (imgSize - size > 0) {
          closest = imgSize;
        }
      }

      return url + '=s' + closest;
    }

    return url;
  },

  listen(eventKey, callback) {
    const viewerEvent = VIEWER_EVENTS[eventKey];
    if (viewerEvent) {
      this.__listeners.push([viewerEvent, callback]);
      this.viewer.on(viewerEvent, callback);
    } else {
      this.host.addEventListener(eventKey, callback);
    }
  },

  unlisten(eventKey, callback) {
    const viewerEvent = VIEWER_EVENTS[eventKey];
    if (viewerEvent) {
      this.viewer.off(viewerEvent, callback);
    } else {
      this.host.removeEventListener(eventKey, callback);
    }
  },

  setPage(pageKey, transition) {
    this.viewer.setPage(pageKey, transition);
  },

  widget(key) {
    return Viewer.getWidget(key);
  }
};

riot.mixin('widget', widgetMixin);
export default widgetMixin;
