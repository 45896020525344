//  import EaseLookup from 'EaseLookup';
export const EASING_FUNCS = [
  'Power0.easeNone',

  'Power2.easeIn',
  'Power2.easeOut',
  'Power2.easeInOut',

  'Elastic.easeIn',
  'Elastic.easeOut',
  'Elastic.easeInOut',

  'Bounce.easeIn',
  'Bounce.easeOut',
  'Bounce.easeInOut',

  'Back.easeIn',
  'Back.easeOut',
  'Back.easeInOut',
];

export function getGsapEasing(index) {
  return EaseLookup.find(EASING_FUNCS[index]);
}
