import EngageTime from './engage-time';
import ScrollDepth from './scroll-depth';

const engageTime = new EngageTime();
const scrollDepth = new ScrollDepth();

function send() {
  if (window.ga) {
    window.ga.apply(window, arguments);
  } // else console.log('send', arguments);
}

export function trackPageView(pagePath) {
  send('set', 'page', pagePath);
  send('send', 'pageview');
}

export function trackScroll(scrollProgress) {
  scrollDepth.track(scrollProgress);
}
