import riot from 'riot';
import WidgetManager from './widget-manager';
import '../mixins/action';
import '../mixins/widget';

const tag = `
  <div if="{type && type !== 'frame' && content}" class="content" data-is="{type}" content="{content}"></div>
  <div class="children">
    <widget each="{ key in children }" ></widget>
  </div>
  <action each="{key in actions}"></action>
`;

riot.tag('widget', tag, '', 'id="{key}" class="{cl}"', function() {
  WidgetManager.register(this);
  this.on('unmount', () => WidgetManager.unregister(this));

  // Editor hack to fix when reordering keys so they're updated
  let lastKey = this.key;
  this.on('update', () => {
    if (lastKey !== this.key) {
      lastKey = this.key;
      WidgetManager.updateTag(this);
    }
  });
  // function fetchData(connect) {
  //   if (connect.source === lastSource && connect.model === lastModel) return;
  //   lastSource = connect.source;
  //   lastModel = connect.model;
  //   self.root.style.display = 'none';
  //   self.viewer
  //     .getDataModel(lastSource, lastModel)
  //     .then(res => {
  //       self.connectData = res;
  //       self.root.style.display = null;
  //       if (tag) {
  //         tag.update({
  //           opts: Object.assign({}, getContent(), self.connectData)
  //         });
  //       }
  //     })
  //     .catch(e => console.log(e));
  // }
});

riot.tag(
  'action',
  '<virtual if="{type && content}" data-is="{type}" content="{content}"></virtual>',
  function() {
    WidgetManager.register(this);
    let lastKey = this.key;
    this.on('unmount', () => WidgetManager.unregister(this));
    this.on('update', () => {
      if (lastKey !== this.key) {
        lastKey = this.key;
        WidgetManager.updateTag(this);
      }
    });
  }
);

riot.tag(
  'icon',
  `<svg if="{type === 0}" riot-viewBox="0 0 {value[0]} {value[1]}"><path each="{path in paths}" riot-d="{path}" /></svg>
  <img if="{type === 1}" riot-src="{value}" />`,
  'icon svg,icon img{width:100%; height:100%; fill:currentColor} icon{display:inline-block;vertical-align:middle; width:1.2em;height:1.2em;}',
  function() {
    let self = this,
      widget,
      icon;
    self.on('mount', () => {
      let parent = self.parent;
      // finding parent widget tag
      let i = 10;
      while (parent.__.tagName !== 'widget' && i--) parent = parent.parent;
      widget = parent;
      update();
    });

    self.parent.on('update', update);
    self.on('update', update);

    function update() {
      if (!widget || !widget.icons) return;
      icon = widget.icons[self.opts.key];
      if (icon && self.value !== icon.value) {
        self.type = icon.type;
        self.value = icon.value;
        if (icon.type === 0) self.paths = icon.value.slice(2);
        if (self.isMounted) self.update();
      }
    }
  }
);
