import riot from 'riot';
type Model = { key: string, type: string, cl: string, content: Object };
const EMPTY_MODEL = {};

class ModelManger {
  models: { [string]: Model } = {};
  widgetTags: riot.Tag[] = [];

  constructor() {
    riot.observable(this);
    window.widgetMan = this;
  }

  update(models: Model[] = []) {
    for (let model of models) {
      let prev = this.models[model.key];
      if (prev !== model) {
        this.models[model.key] = model;
        const tag = this.getTag(model.key);
        if (tag) this.updateTag(tag, true);
        this.trigger('change', model);
      }
    }
  }

  updateTag(tag: riot.Tag, doUpdate: boolean) {
    let model: Model = this.getModel(tag.key);
    // let tag: riot.Tag = this.getTag(key);

    if (!model) return;
    if (tag.type && model.type !== tag.type && tag.tags[tag.type]) {
      tag.tags[tag.type].root.innerHTML = '';
    }
    tag.type = model.type;
    tag.cl = model.cl;
    tag.children = model.children;
    tag.actions = model.actions;
    tag.content = model.content;
    tag.icons = model.icons;

    if (tag.isMounted && doUpdate) tag.update();
  }

  register = (widgetTag: riot.Tag) => {
    let id = widgetTag.key;
    this.widgetTags.push(widgetTag);
    let viewer = widgetTag;
    let page;
    while (viewer.parent) {
      page = viewer;
      viewer = viewer.parent;
    }
    widgetTag.viewer = viewer;
    widgetTag.page = page;
    if (id) this.updateTag(widgetTag);
  };

  unregister = (widgetTag: riot.Tag) => {
    const index = this.widgetTags.indexOf(widgetTag);
    if (index !== -1) this.widgetTags.splice(index, 1);
  };

  getTag(key: string): riot.Tag {
    let i = this.widgetTags.length;
    while (i--) {
      if (this.widgetTags[i].key === key) return this.widgetTags[i];
    }
  }

  getModel(key: string): Model {
    return this.models[key] || EMPTY_MODEL;
  }
}

export default new ModelManger();
