import './widget';
import riot from 'riot';
import TimelineManager from '../animation/timeline-manager';
import TweenLite from 'TweenLite';
import WindowManager from '../utils/window-manager';
import WidgetManager from './widget-manager';

const EMPTY_LIST = [];
const tag = `
<div if="{visible}" ref="content" class="page-content" riot-style="transform:{contentStyle || 'none'}">
  <widget each="{ key in opts.children }" section ></widget>
  <div each="{page, index in pages}" key="key" class="page-holder" riot-style="height:{pageStyles[index] || 0}" >
    <page key="{page.key}" actions="{page.actions}" children="{page.children}"></page>
  </div>
</div>`;

const CENTER_VALUES = { x: 0, y: 0, opacity: 1, scale: 1 };

riot.tag('page', tag, function() {
  const self = this;
  const timeline: TimelineManager = new TimelineManager(
    null,
    onTransitionComplete,
    onTransitionComplete
  );
  let activeTransitionIndex: number = -1,
    toPage,
    fromPage,
    lastViewHeight,
    timeout,
    blockScroll = false,
    hasListeners = false,
    visibleRanges = [],
    transitionRanges = [],
    rootClass = [],
    pageStyles = [];

  const parent = self.parent;
  const root = self.root;

  timeline.addFormat('y', v => v * 100 + 'vh');
  timeline.addFormat('x', v => v * 100 + 'vw');
  timeline.addReplace('y', 'top');
  timeline.addReplace('x', 'left');

  self.pages = EMPTY_LIST;
  self.visible = true;
  self.contentStyle = 'none';
  self.pageStyles = pageStyles;
  self.pin = pin;
  self.unPin = unPin;
  self.transitionFrom = transitionFrom;
  self.transitionTo = transitionTo;
  self.setVisibility = setVisibility;
  self.setContent = setContent;

  self.on('before-mount', onBeforeMount);
  self.on('mount', onMount);
  self.on('unmount', onUnmount);
  self.on('update', onUpdate);
  self.on('updated', onUpdated);

  function setContent(content) {
    if (toPage || fromPage) transitionDone();
    self.update(content);
  }

  function onBeforeMount() {
    onUpdate();
  }

  function onMount() {
    onUpdated();
    WidgetManager.on('change', onModelChange);
  }

  function onUnmount() {
    removeListeners();
    WidgetManager.off('change', onModelChange);
  }

  function onModelChange(model) {
    if (self.pages) {
      for (let i = 0; i < self.pages.length; i++) {
        if (self.pages[i].key === model.key) {
          lastViewHeight = 0;
          self.pages[i] = model;
          return updateTimeline();
        }
      }
    }
    if (!self.pages.length && self.key === model.key) {
      self.update({ opts: model });
    }
  }

  function addListeners() {
    if (!hasListeners) {
      hasListeners = true;
      WindowManager.on('scroll', handleScroll);
      WindowManager.on('resize', handleResize);
    }
  }

  function removeListeners() {
    if (hasListeners) {
      hasListeners = false;
      WindowManager.off('scroll', handleScroll);
      WindowManager.off('resize', handleResize);
    }
  }

  function getOffsetTop(): number {
    return self.pinned && self.pages.length ? self.offsetTop : WindowManager.getScrollTop();
  }

  function onUpdate() {
    const { opts } = self;
    self.prevPages = opts.pages;
    // if (!update || !update.opts) return;

    self.pages = (opts.pages || EMPTY_LIST)
      .map(page => WidgetManager.getModel(page.key))
      .filter(page => !!page);

    lastViewHeight = 0;
    visibleRanges.length = pageStyles.length = transitionRanges.length = self.pages.length;

    if (!self.pages.length) {
      activeTransitionIndex = -1;
      delete self.localPageKey;
      removeListeners();
    } else {
      addListeners();
      updateTimeline();
    }
  }

  function onUpdated() {
    if (self.pages.length && self.localPageKey !== self.key) {
      self.localPageKey = self.key;
      if (activeTransitionIndex !== -1) {
        transitionDone();
      }
      WindowManager.scrollToPage(self.key);
      handleScroll();
    }
  }

  function handleResize() {
    updateTimeline();
    handleScroll();
    // self.update({ pageStyles });
  }

  function getHeightStyle(staticHeight: number, dynamicHeight: number): string {
    if (dynamicHeight && staticHeight) {
      return `calc(${staticHeight}px + ${dynamicHeight * 100}vh)`;
    }
    if (dynamicHeight) return `${dynamicHeight * 100}vh`;
    return `${staticHeight}px`;
  }

  function updateTimeline() {
    const viewHeight = WindowManager.getHeight();
    if (!self.pages.length || lastViewHeight === viewHeight) return;

    lastViewHeight = viewHeight;
    const defaultTransition = self.opts.transition;

    let offset = 0;
    for (let i = 0; i < self.pages.length; i++) {
      const { key, sizes } = self.pages[i];
      const [staticHeight, dynamicHeight] = sizes[WindowManager.getDeviceMode()] || sizes.desktop;
      const pageHeight = Math.max(staticHeight + dynamicHeight * viewHeight, viewHeight);
      WindowManager.setPageStats(key, offset, pageHeight);

      pageStyles[i] = getHeightStyle(staticHeight, dynamicHeight);
      // Page is visible when its in range of 1 viewport of the scroll top
      visibleRanges[i] = [offset - viewHeight * 2, offset + pageHeight + viewHeight];
      if (i > 0) {
        transitionRanges[i] = [
          offset - viewHeight,
          offset,
          self.opts.pages[i - 1].transition || defaultTransition
        ];
      }

      offset += pageHeight;
    }
  }

  function getTransition(
    pageIndex: number
  ): { tweenIn: Object, tweenOut: Object, inFront: boolean } {
    return transitionRanges[pageIndex][2];
  }

  function isPageVisible(pageIndex): boolean {
    return WindowManager.isScrollInRange(visibleRanges[pageIndex]);
  }

  function isTransitionInRange(pageIndex) {
    return WindowManager.isScrollInRange(transitionRanges[pageIndex]);
  }

  function handleScroll() {
    if (self.pinned || blockScroll) return;
    const pageTags = self.tags.page;

    if (pageTags && self.pages.length === pageTags.length) {
      const scrollTop = getOffsetTop();
      if (activeTransitionIndex === -1) {
        for (let i = 0; i < self.pages.length; i++) {
          const visible = isPageVisible(i);
          const page = pageTags[i];
          if (page.visible !== visible) {
            page.update({ visible });
          }

          if (i > 0 && isTransitionInRange(i)) {
            fromPage = pageTags[i - 1];
            toPage = page;

            const { inFront, tweenIn, tweenOut } = getTransition(i);
            activeTransitionIndex = i;

            fromPage.pin(!inFront, true);
            toPage.pin(inFront, false, 0);

            fromPage.transitionFrom(timeline, tweenOut);
            toPage.transitionTo(timeline, tweenIn);
          }
        }
      }

      if (activeTransitionIndex !== -1) {
        const transitionRange = transitionRanges[activeTransitionIndex];
        const progress =
          (scrollTop - transitionRange[0]) / (transitionRange[1] - transitionRange[0]);

        timeline.progress(progress);
        if (transitionRange[2].snap) {
          clearTimeout(timeout);
          timeout = setTimeout(transitionTimeout, 300);
        }

        if (progress < 0.5 && fromPage.opts.key !== self.key) {
          setActivePage(fromPage.opts.key);
        } else if (progress > 0.5 && toPage.opts.key !== self.key) {
          setActivePage(toPage.opts.key);
        }

        if (progress >= 1 || progress <= 0) {
          transitionDone();
        }
      }
    }
  }

  function transitionTimeout() {
    blockScroll = true;
    timeline[timeline.progress() < 0.5 ? 'reverse' : 'play']();
  }

  function onTransitionComplete() {
    if (blockScroll) {
      const range = transitionRanges[activeTransitionIndex];
      WindowManager.setScrollTop(range[(timeline.progress() >= 0.5) * 1]);
      blockScroll = false;
      transitionDone();
    }
  }

  function transitionDone() {
    activeTransitionIndex = -1;
    toPage.unPin();
    fromPage.unPin();
    clearTimeout(timeout);
    timeline.pause();
    timeline.clear();
  }

  function setActivePage(newPageKey) {
    if (self.key !== newPageKey) {
      self.key = self.localPageKey = newPageKey;
      parent.setPage(newPageKey);
    }
  }

  function setVisibility(visible: boolean) {
    return (self.visible = visible) ? removeClass('hidden') : addClass('hidden');
  }

  function pin(inFront, attachBottom, offsetTop = 0) {
    self.pinned = true;
    let cl = ['pin'];
    addClass('pin');

    if (inFront) cl.push('front');

    if (attachBottom) cl.push('bottom');
    else setContentOffset(offsetTop);

    addClass(cl);
  }

  function unPin() {
    self.pinned = false;
    TweenLite.set(root, { clearProps: 'all' });
    removeClass('pin', 'front', 'bottom');
    setContentOffset(0);
  }

  function removeClass(className: string | string[]) {
    const classNames = typeof className === 'string' ? arguments : className;

    for (let i = 0; i < classNames.length; i++) {
      const index = rootClass.indexOf(classNames[i]);
      if (index !== -1) {
        rootClass.splice(index, 1);
      }
    }

    updateClassname();
  }

  function addClass(className: string | string[]) {
    const classNames = typeof className === 'string' ? arguments : className;
    for (let i = 0; i < classNames.length; i++) {
      if (rootClass.indexOf(classNames[i]) === -1) {
        rootClass.push(classNames[i]);
      }
    }

    updateClassname();
  }

  function updateClassname() {
    root.setAttribute('class', rootClass.join(' '));
  }

  function setContentOffset(offsetTop) {
    self.offsetTop = offsetTop;
    if (activeTransitionIndex === -1) {
      self.update({
        contentStyle: offsetTop ? `translate3d(0,${-offsetTop}px,0)` : 'none'
      });
    }
  }

  function transitionFrom(timeline: TimelineManager, tweens: Array) {
    timeline.buildTweenFromValues(root, tweens, CENTER_VALUES);
  }

  function transitionTo(timeline: TimelineManager, tweens: Array) {
    timeline.buildTweenToValues(root, tweens, CENTER_VALUES);
  }
});
