export default class AnalyticsEvent {
  category: String;
  nonInteraction: Boolean;
  constructor(category, nonInteraction: Boolean) {
    this.category = category;
    this.nonInteraction = nonInteraction;
  }

  send(eventAction, eventLabel, eventValue) {
    if(typeof dataLayer !== 'undefined'){
      dataLayer.push({
        event : 'vev',
        eventCategory : this.category,
        eventAction,
        eventLabel,
        eventValue
      });
    } else if (window.ga) {
      window.ga('send', 'event', this.category, eventAction, eventLabel, eventValue, {
        nonInteraction: this.nonInteraction || false
      });
    }
  }

  throttle(func, wait) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    var later = () => {
      previous = new Date();
      timeout = null;
      result = func.apply(context, args);
    };
    return function() {
      var now = new Date();
      if (!previous) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(context, args);
      } else if (!timeout) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  }
}
