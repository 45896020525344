import AnalyticsEvent from './analytics-event';

const EVENT_ACTION = 'Percentage';

export default class ScrollDepth extends AnalyticsEvent {
  depth = {};
  stepSize = 10;
  startTime = Date.now();

  constructor() {
    super('Vev Scroll Depth', false);
  }

  getProgress() {
    return this.depth[location.pathname] || 0;
  }

  getTiming() {
    return Math.round((Date.now() - this.startTime) / 1000);
  }

  track = (progress: Number) => {
    progress = Math.max(0, Math.min(1, progress));
    progress = Math.round(progress * 10) / 10;
    progress = Math.round(progress * 100);

    let prevProgress = this.getProgress();

    if (prevProgress < progress) {
      const timing = this.getTiming();
      do {
        prevProgress += this.stepSize;
        this.send(EVENT_ACTION, progress, timing);
      } while (prevProgress < progress);

      this.depth[location.pathname] = prevProgress;
    }
  };
}
