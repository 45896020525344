function withoutEasing(transition) {
  transition = Object.assign({}, transition);
  if (transition.tweenIn) removeEasing(transition.tweenIn);
  if (transition.tweenOut) removeEasing(transition.tweenOut);

  return transition;
}

function removeEasing(tween) {
  for (var attr in tween) {
    if (attr === 'time') continue;
    tween[attr][1] = 0;
  }
}

type TransitionModel = { tweenIn: Object, tweenOut: Object, inFront: boolean };

type StoryModel = {
  pages: { key: string, transition?: TransitionModel }[],
  transition: TransitionModel
};

export default class Storyline {
  last: StoryModel[];
  models: Map<string, StoryModel>;
  pages = {};
  scrollTop = 0;

  pageStoryKey = {};

  /**
   * Search for the storyline of a page, returns the storykey if any stiryline is found
   * @param {String} pageKey - key of page to get story of
   * @returns {String} - storyeline key
   */
  getPageStoryKey(pageKey) {
    return this.pageStoryKey[pageKey];
  }

  getPageStory(pageKey: string): StoryModel {
    const storyKey = this.getPageStoryKey(pageKey);
    if (storyKey) return this.getStory(storyKey);
  }

  getStory(storylineKey: string): StoryModel {
    return this.models[storylineKey];
  }

  /**
   * Update the storline model
   */
  update(stories: StoryModel[]): void {
    if (stories === this.last) return false;
    this.last = stories;
    this.models = {};
    this.pageStoryKey = {};
    if (stories && stories.length) {
      for (let story of stories) {
        const pages = story.pages || [];
        this.models[story.key] = story;
        for (var page of pages) {
          if (page) {
            this.pageStoryKey[page.key] = story.key;
          }
        }
      }
    }

    return true;
  }

  hasModel(pageKey = this.activeKey) {
    return this.pages[pageKey] !== undefined;
  }

  getModel(pageKey = this.activeKey) {
    return this.pages[pageKey] || {};
  }

  getDirectionModel(pageKey, dir) {
    return this.getModel(pageKey)[dir < 0 ? 'top' : 'bottom'];
  }
}
