const NOT_LOADED = 0;
const LOADING = 1;
const LOADED = 2;
const ERROR_LOADING = 3;

function getValue(object, path) {
  let splitPath = path.split('.');
  let value = object;
  for (var key of splitPath) {
    if (value && typeof value === 'object') {
      value = value[key];
    } else {
      console.log('reject', key);
      return Promise.reject(`Failed to get path ${path}, "${key}" does not exist`);
    }
  }

  return Promise.resolve(value);
}

export default class Datasource {
  url;
  state = NOT_LOADED;
  pending = [];
  constructor(model) {
    this.update(model);
  }

  update(model) {
    if (this.url !== model.url) {
      this.state = NOT_LOADED;
      this.url = model.url;
      for (var promise of this.pending) promise.reject();
      this.pending = [];
    }
  }

  load() {
    if (this.state !== NOT_LOADED) Promise.reject('All ready loaded');

    this.state = LOADING;
    return fetch(this.url)
      .then((res) => res.json())
      .then((res) => {
        this.data = res;
        this.state = LOADED;
        for (var promise of this.pending) promise.resolve();
        this.pending = [];
      }).catch((e) => {
        this.state = ERROR_LOADING;
        for (var promise of this.pending) promise.reject(e);
        this.pending = [];
      });
  }

  get(path) {
    switch (this.state) {
      case ERROR_LOADING:
        return Promise.reject('Failed to load resource');
      case LOADED:
        return getValue(this.data, path);
      case NOT_LOADED:
        this.load();
        break;
    }

    return new Promise((resolve, reject) => {
      this.pending.push({ resolve, reject });
    }).then(() => {
      return getValue(this.data, path);
    });

  }
}
