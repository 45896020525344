import riot from 'riot';
import WidgetMixin from './widget';

riot.mixin(
  'action',
  Object.assign({}, WidgetMixin, {
    init: function() {
      WidgetMixin.init.call(this);
      this.viewer = this.parent.viewer;
      this.host = this.parent.parent.root;
    }
  })
);
