import riot from 'riot';
import { trackPageView } from '../analytics';
const EVENTS = {
  change: 'change'
};
const REMOVE_DOUBLE_SLASH = /\/{2,}/g;

class Router {
  isEnabled = false;
  urlMap = {};
  idMap = {};
  constructor() {
    riot.observable(this);
    this._handleHistoryChange = ::this._handleHistoryChange;
    this._handleActivePage = ::this._handleHistoryChange;
    this.lastPath = this.getPath();
    this.baseDir = ('/' + (window.dir || '') + '/').replace(REMOVE_DOUBLE_SLASH, '/');
    this.enable();
  }

  disable() {
    this.isEnabled = false;
    window.removeEventListener('popstate', this._handleHistoryChange);
  }

  enable() {
    this.isEnabled = true;
    window.addEventListener('popstate', this._handleHistoryChange);
  }

  update(urlMap) {
    if (this.urlMapModel === urlMap) return;
    this.urlMapModel = urlMap;
    // Create absolute paths using the baseDir
    for (var pagePath in urlMap) {
      const pageKey = urlMap[pagePath];
      var absolutePath = `${this.baseDir}${pagePath}/`.replace(REMOVE_DOUBLE_SLASH, '/');
      this.urlMap[absolutePath] = pageKey;
      // Flipping the urlmap so lookup on pageid is possible
      this.idMap[pageKey] = absolutePath;
    }
  }

  getPagePath(pageKey: string) {
    return this.idMap[pageKey] || this.baseDir;
  }

  getPageKeyByPath(path = this.getPath()) {
    if (this.urlMap[path]) return this.urlMap[path];

    let key = path.substr(1);
    if (this.idMap[key]) return key;

    return this.urlMap[this.baseDir];
  }

  getPath() {
    return (document.location.pathname + '/').replace(REMOVE_DOUBLE_SLASH, '/');
  }

  isLastPath(path) {
    return path === this.lastPath;
  }

  set(title, pageKey, prevPageKey) {
    if (!this.isEnabled) return;
    document.title = title;

    const path = this.getPagePath(pageKey);
    if (!this.isLastPath(path)) {
      this.lastPath = path;
      window.history.pushState(
        { pageKey, prevPageKey, title },
        title,
        path + location.search + location.hash
      );

      trackPageView(path);
    }
  }

  _handleHistoryChange(e) {
    const path = this.getPath();
    if (!this.isLastPath(path)) {
      document.title = e.state.title;
      this.lastPath = path;
      this.trigger(EVENTS.change, e.state);
    }
  }
}

export default new Router();
