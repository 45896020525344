import riot from 'riot';

const shapeHtml =
  '<svg riot-preserveAspectRatio="{opts.stretch ? \'none\' : \'xMidYMid \'}" riot-viewBox="0 0 {w} {h}"><path each="{path in paths}" riot-d="{path}" /></svg>';
const shapeCss = '.shape svg{width:100%;height:100%;fill:currentColor}';
function shapeFunc() {
  const self = this;
  self.w = 100;
  self.h = 100;
  self.paths = ['M50 0 L100 100  L0 100 Z'];

  self.mixin('widget');
  self.on('before-mount', init);
  self.on('update', init);
  // const root = self.root;
  // init();
  // self.on('update', init);
  const viewer = self.viewer;
  function init() {
    const pathModel = viewer.shapes[self.opts.shapeId];
    if (pathModel) {
      self.w = pathModel[0];
      self.h = pathModel[1];
      self.paths = pathModel.slice(2);
    }
  }
}
riot.tag('shape', shapeHtml, shapeCss, '', shapeFunc);

riot.tag('rectangle', ' ', '.rectangle{background-color:#000}');
riot.tag('section', ' ', '.section{height:900px}');
riot.tag('circle', ' ', '.circle{border-radius:50%;background-color:#000}');

riot.tag(
  'image',
  '<img riot-src="{src()}" riot-srcset="{srcSet()}" riot-sizes="{sizes()}"/>',
  '.image{overflow:hidden}.image img{object-fit:cover;height:100%;width:100%;}',
  '',
  function() {
    var b = this;
    b.mixin('widget');
    b.src = function() {
      return b.opts.src && b.opts.src.url ? b.opts.src.url : b.opts.src;
    };
    b.srcSet = function() {
      return b.opts.src && b.opts.src.srcSet;
    };
    b.sizes = function() {
      return b.opts.src && b.opts.src.sizes;
    };
  }
);
