import AnalyticsEvent from './analytics-event';

const CATEGORY = 'Vev Engage Time';
const TIME_ACTION = 'Time Spent';
const FIRST_ACTION = 'First Interaction';

export default class EngageTime extends AnalyticsEvent {
  idleTimeout = 30;
  reportInterval = 5;
  started = false;
  stopped = false;
  startTime = new Date();
  clockTime = 0;

  constructor() {
    super(CATEGORY, true);
    // Basic activity event listeners
    this.delayed = this.throttle(this.trigger, 500);
    document.addEventListener('keydown', this.trigger);
    document.addEventListener('click', this.trigger);
    window.addEventListener('mousemove', this.delayed);
    window.addEventListener('scroll', this.delayed);

    // Page visibility listeners
    document.addEventListener('visibilitychange', this.visibility);
    document.addEventListener('webkitvisibilitychange', this.visibility);

    window.addEventListener('beforeunload', this.stop);
  }

  clock = () => {
    this.clockTime++;
    if (this.clockTime > 0 && this.clockTime % this.reportInterval === 0) {
      this.send(TIME_ACTION, this.clockTime.toString(), this.reportInterval);
    }
  };

  visibility = () => {
    if (document.hidden || document.webkitHidden) {
      this.idle();
    }
  };

  idle = () => {
    clearTimeout(this.idleTimer);
    this.stop();
  };

  trigger = () => {
    if (!this.started) this.start();
    if (this.stopped) this.restart();

    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(this.idle, this.idleTimeout * 1000 + 100);
  };

  start() {
    this.started = true;
    // Calculate seconds from start to first interaction
    const time = Math.round((new Date() - this.startTime) / 1000);
    this.send(FIRST_ACTION, time.toString());
    // Start clock
    this.clockTimer = setInterval(this.clock, 1000);
  }

  stop = () => {
    this.stopped = true;
    clearInterval(this.clockTimer);
  };

  restart() {
    this.stopped = false;
    this.stop();
    this.clockTimer = setInterval(this.clock, 1000);
  }
  reset() {
    this.stop();
    this.startTime = new Date();
    this.clockTime = 0;
    this.started = false;
    this.stopped = false;
    clearTimeout(this.idleTimer);
  }
}
